import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import MainLayout from '../../components/MainLayout';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { validateUserPayment } from '../../utils/http';

const PaymentStatus = () => {
    const navigate = useNavigate();
    const { status } = useParams();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (status === 'success') {
            getUserSubscriptionStatus();
        } else {
            setLoading(false);
        }
    }, [status])

    const getUserSubscriptionStatus = async () => {
        const { data } = await validateUserPayment({});
        if (data?.status === 'success') {
            localStorage.setItem("isSubscribed", true);
            localStorage.setItem("currentPackage", data?.currentPackage)
        }

        setLoading(false);
    }

    return (
        <MainLayout showPlanButton={status !== 'success'} removePadding>
            <Loader open={loading} />
            <Stack gap={8} alignItems={'center'} justifyContent={'center'} height={'100vh'}>
                <Typography variant="h4" fontWeight={500} sx={{ color: 'primary.main' }}>
                    {status === 'success' ?
                        `Let's create our first form!` :
                        'There is some issue in processing payment'
                    }
                </Typography>
                {status === 'success' ? (
                    <Button
                        variant='contained'
                        size='medium'
                        onClick={() => navigate('/questionnaire', { state: { showCreate: true } })}
                    >
                        Create Form
                    </Button>
                ) :
                    <Button
                        variant='contained'
                        size='medium'
                        onClick={() => navigate('/')}
                    >
                        Back to Plans
                    </Button>
                }
            </Stack>
        </MainLayout>
    )
}

export default PaymentStatus