import { Navigate, Outlet, } from "react-router-dom";
import { getLoggedInUserToken, getLoggedInUserSubscriptionStatus } from "../utils/methods";

const PrivateRoutes = () => {
  const isAuthenticated = getLoggedInUserToken();
  const isSubscribed = getLoggedInUserSubscriptionStatus()

  if (isAuthenticated) {
    if (isSubscribed) {
      // Render the nested routes
      return <Outlet />;
    } else {
      // Redirect to plans page if not subscribed
      return <Navigate to="/not-subscribed" />;
    }
  } else {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
