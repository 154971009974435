import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledDataGrid } from '../../utils/TableStyle';
import MainLayout from '../../components/MainLayout';
import { deleteLocation, getAllLocations } from '../../utils/http';
import Loader from '../../components/Loader';
import AddNewLocation from './AddNewLocation';
import { ToastContainer, toast } from 'react-toastify';
import Carousel from 'react-material-ui-carousel'
import { toastConfiguration } from '../../utils/contants';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

let isMaxWidth1300 = false;

const commonColumnProps = {
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    hideSortIcons: true
}

const columns = [
    {
        field: 'location',
        headerName: 'Location',
        ...commonColumnProps
    },
    {
        field: 'subLocations',
        headerName: 'Sub Locations',
        renderCell: (params) => (
            <Box sx={{ height: 52 }}>
                <Carousel
                    autoPlay={false}
                    cycleNavigation={false}
                    stopAutoPlayOnHover={true}
                    NextIcon={<ArrowRight sx={{ height: '12px', width: '12px' }} />}
                    PrevIcon={<ArrowLeft sx={{ height: '12px', width: '12px' }} />}
                    navButtonsProps={{
                        style: {
                            opacity: isMaxWidth1300 ? 0 : 0.8,
                            backgroundColor: '#FF5055',
                            position: 'unset'
                        }
                    }}
                >
                    {
                        params?.row?.subLocations?.map((item, i) => <span key={i}>{item}</span>)
                    }
                </Carousel>
            </Box>
        ),
        resizable: true,
        ...commonColumnProps,
        flex: isMaxWidth1300 ? 1 : 2
    },
    {
        field: 'address',
        headerName: 'Address',
        ...commonColumnProps
    },
    {
        field: 'city',
        headerName: 'City',
        ...commonColumnProps
    },
    {
        field: 'state',
        headerName: 'State',
        ...commonColumnProps
    },
    {
        field: 'notes',
        headerName: 'Notes',
        ...commonColumnProps
    }
];

const NoRowsOverlay = (onClickHandler) => {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={2}
        >
            <Typography sx={{ mb: 2 }}>No locations to display</Typography>
            <Button
                variant='contained'
                size='small'
                onClick={onClickHandler}
            >
                Add Location
            </Button>
        </Box>
    );
}

const Locations = () => {
    const [loading, setLoading] = React.useState(true);
    const [showAddNewLocation, setShowAddNewLocation] = React.useState(false);
    const [locations, setLocations] = React.useState([]);
    const [selectedRowId, setSelectedRowId] = React.useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false)

    isMaxWidth1300 = useMediaQuery('(max-width:1300px)');

    React.useEffect(() => {
        getUserRegisteredLocations();
    }, [])

    const getUserRegisteredLocations = async () => {
        const { data } = await getAllLocations();
        if (data?.locations) {
            const dbLocations = data?.locations?.map(item => { return { ...item, id: item._id } })
            setLocations(dbLocations || []);
        }

        setLoading(false);
    }

    const handleClose = () => {
        setSelectedRowId(null);
        setShowConfirmationModal(false);
    }

    const deleteHandler = async () => {
        setLoading(true);
        setShowConfirmationModal(false);

        const { data, error } = await deleteLocation(selectedRowId);
        if (data) {
            await getUserRegisteredLocations();
            toast.success("Location deleted successfully", toastConfiguration);
        } else if (error) {
            toast.error(error || "Error in deleting location", toastConfiguration)
        }

        setSelectedRowId(null);
        setLoading(false);
    }

    return (
        <MainLayout hideUI>
            <ToastContainer />
            <Loader open={loading} />
            <Dialog
                open={showConfirmationModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Location?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete location ? You cannot revert this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={deleteHandler} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {showAddNewLocation ?
                <AddNewLocation
                    id={selectedRowId}
                    loading={loading}
                    setLoading={setLoading}
                    setSelectedRowId={setSelectedRowId}
                    setShowAddNewLocation={setShowAddNewLocation}
                    getUserRegisteredLocations={getUserRegisteredLocations}
                />
                :
                <>
                    <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} mb={2}>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => setShowAddNewLocation(true)}
                        >
                            Add Location
                        </Button>
                    </Box>
                    <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                        <StyledDataGrid
                            slots={{
                                noRowsOverlay: () => NoRowsOverlay(() => setShowAddNewLocation(true)),
                            }}
                            rows={locations}
                            columns={[...(columns || []), {
                                field: 'actions',
                                headerName: 'Actions',
                                renderCell: (params) => (
                                    <Box >
                                        <IconButton
                                            color="primary"
                                            aria-label="edit"
                                            onClick={() => {
                                                setLoading(true);
                                                setSelectedRowId(params.row.id);
                                                setShowAddNewLocation(true);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            aria-label="delete"
                                            onClick={() => {
                                                setSelectedRowId(params.row.id);
                                                setShowConfirmationModal(true);
                                            }}
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ),
                                ...commonColumnProps
                            }]}
                            disableColumnMenu
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 20]}
                            rowHeight={52}
                            columnHeaderHeight={42}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </>
            }
        </MainLayout>
    )
}

export default Locations