import { loadStripe } from "@stripe/stripe-js";
import { productPlans } from "./contants";
import { createClientSession } from "./http";

export const getLoggedInUserToken = () => {
    return localStorage.getItem("token");
}

export const getLoggedInUserSubscriptionStatus = () => {
    return localStorage.getItem("isSubscribed") === "false" ? false : true;
}

export const stripeHandler = async (planType) => {
    const plan = productPlans?.find(item => item.plan_type === planType);
    const { data } = await createClientSession({
        product: plan
    })

    if (data?.id) {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        stripe.redirectToCheckout({
            sessionId: data.id
        })
    }
}

export const dateFormatter = (date = new Date()) => {
    if (date) { date = new Date(date) }
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}

export const timeFormatter = (date = new Date()) => {
    if (date) { date = new Date(date) }

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, '0');

    return `${formattedHours}:${minutes} ${ampm}`;
}

export const sentenceCase = (value = '') => {
    if (!value) return value;
    if (value?.charAt(0) !== value?.charAt(0)?.toLowerCase()) {
        return value;
    }
    return value?.charAt(0)?.toUpperCase() + value?.slice(1)?.toLowerCase()
}