export const toastConfiguration = {
    position: "bottom-right",
    autoClose: 4000,
    theme: "colored",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

export const productPlans = [
    {
        name: 'Standard',
        price: 29,
        priceYearly: 22,
        type: 'per month',
        features: ['Single Location Management', 'Task Assignment and Tracking', 'Basic Reporting', 'Upto 5 user staffs bar staff', 'Email Support'],
        color: 'success',
        plan_type: 'standard',
    },
    {
        name: 'Premium',
        price: 69,
        priceYearly: 62,
        type: 'per month',
        features: ['Multiple Location Management', 'Upto 3 bar Locations', 'Enhanced Reporting', 'Upto 15 user accounts for bar staff', 'Task Templates', 'Email Suport'],
        color: 'primary',
        plan_type: 'premium',
    },
    {
        name: 'Enterprise',
        price: 'Contact us',
        priceYearly: 'Contact us',
        type: '',
        features: [`If you need more than what's offered in our standard plans, reach out for a chat with the member of our team.`],
        color: 'primary',
        plan_type: 'enterprise',
    }
]

export const Messages = {
    emailPendingConfirmation: 'Email verification is pending'
}