// handleSelect.js
export const handleSelect = (element, fieldId) => {
    const addOptionButton = element.querySelector('.add.add-opt');
    const sortableHandles = element.querySelectorAll('.ui-sortable-handle');
    const radioName = `${fieldId}-0-options`;

    sortableHandles?.forEach((handle, index) => {
        const input = document.createElement('input');
        input.value = `value-${index}`;
        input.type = 'radio';
        input.name = radioName;
        input.setAttribute('data-attr', 'selected');
        input.className = 'reason-option-x option-selected option-attr';
        handle.appendChild(input);
        const span = document.createElement('span');
        span.innerHTML = 'Get Reason'
        span.className = 'option-selected option-attr';
        handle.appendChild(span);
    });

    if (addOptionButton) {
        addOptionButton.addEventListener('click', () => {
            setTimeout(() => {
                const sortableUpdatedHandlers = element.querySelectorAll('.ui-sortable-handle');
                if (sortableUpdatedHandlers && sortableUpdatedHandlers[0]) {
                    const parentElement = sortableUpdatedHandlers[0]?.parentElement;
                    if (parentElement) {
                        const childrens = parentElement?.children;
                        if (childrens?.length > 0) {
                            const newElement = childrens[childrens?.length - 1]
                            if (newElement) {
                                const input = document.createElement('input');
                                input.value = `value-${childrens?.length - 1}`;
                                input.type = 'radio';
                                input.name = radioName;
                                input.setAttribute('data-attr', 'selected');
                                input.className = 'reason-option-x option-selected option-attr';
                                newElement.appendChild(input);
                                const span = document.createElement('span');
                                span.innerHTML = 'Get Reason'
                                span.className = 'option-selected option-attr';
                                newElement.appendChild(span);
                            }
                        }
                    }
                }
            }, 100);
        });
    }
};
