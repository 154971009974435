import React from 'react';
import { Box } from '@mui/material';

const Container = ({ children }) => {
    return (
        <Box
            sx={{
                px: 3,
                mx: 'auto',
                maxWidth: {
                    sm: '100%',
                    md: '90%',
                    lg: '90%',
                    xl: '90%',
                }
            }}>
            {children}
        </Box>
    )
}

export default Container
