
import React, { useState } from 'react';
import { Modal, Box, Avatar, Button } from '@mui/material';

const ImageModal = ({ open, handleClose, images }) => (
    <Modal open={open} onClose={handleClose}>
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                p: 2,
                backgroundColor: 'white',
                outline: 'none',
                maxHeight: '80vh',
                overflowY: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            {images.map((image, index) => (
                <Box key={index} sx={{ p: 1 }}>
                    <Avatar src={image} sx={{ width: '300px', height: '300px', borderRadius: '12px' }} />
                </Box>
            ))}
        </Box>
    </Modal>
);

const ImageButtonWithModal = ({ images }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {images.length > 0 && (
                <>
                    <Button onClick={handleOpen}>
                        +{images.length}
                    </Button>
                    <ImageModal open={open} handleClose={handleClose} images={images} />
                </>
            )}
        </>
    );
};

export default ImageButtonWithModal;
