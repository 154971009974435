import React, { useState } from 'react';
import { Box, Button, Divider, Typography, alpha } from '@mui/material';
import CheckIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUserToken, stripeHandler } from '../../../utils/methods';
import Loader from '../../../components/Loader';

const Card = (props) => {
    const { name, type, price, features, priceYearly, plan_type } = props
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const isEnterprise = name === 'Enterprise';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 4,
                height: '100%',
                boxShadow: 3,
                backgroundColor: 'unset',
                '&:hover': {
                    backgroundColor: 'background.default',
                },
            }}
        >
            <Loader open={loading} />
            <Box sx={{ p: 3 }}>
                <Typography
                    variant="h5"
                    sx={{ mb: 1, textTransform: 'uppercase' }}
                    color={'textSecondary'}
                >
                    {name}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    {isEnterprise ?
                        <Typography variant="h4" fontWeight={600} marginTop={2}>
                            Contact us
                        </Typography>
                        :
                        <Typography variant="h3" fontWeight={600}>
                            ${props?.checked ? price : priceYearly}
                        </Typography>
                    }
                    <Typography
                        color="textSecondary"
                        sx={{
                            alignSelf: 'flex-end',
                            ml: 1
                        }}
                        variant="subtitle2"
                    >
                        {type}
                    </Typography>
                </Box>
            </Box>
            <Divider sx={{ borderColor: '#eaeaea' }} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,

                    p: 3
                }}
            >
                {features.map((feature, i) => (
                    <Box
                        key={i}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            '& + &': {
                                mt: 1
                            }
                        }}
                    >
                        {!isEnterprise && (
                            <CheckIcon
                                fontSize="small"
                                sx={{ fontSize: 8 }}
                            />
                        )}
                        <Typography
                            sx={{
                                fontWeight: 500,
                                ml: 2
                            }}
                            variant="body2"
                        >
                            {feature}
                        </Typography>
                    </Box>
                ))}
                <Box sx={{ flexGrow: 1 }} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 6
                    }}
                >
                    <Button
                        fullWidth
                        variant={'contained'}
                        size='small'
                        disabled={props?.isPurchased}
                        sx={{
                            color: '#fff',
                            backgroundColor: '#4E4F50',
                            '&:hover': {
                                backgroundColor: 'grey',
                            }
                        }}
                        onClick={async () => {
                            if (isEnterprise) {
                                const mailtoLink = `mailto:${'muddle@gobarkeep.com'}?subject=${encodeURIComponent('Discussion on enterprise package plan')}`;
                                window.location.href = mailtoLink;
                            } else {
                                if (getLoggedInUserToken()) {
                                    setLoading(true)
                                    await stripeHandler(plan_type);
                                    setLoading(false)
                                } else {
                                    navigate('/signup', { state: { planType: plan_type } });
                                }
                            }
                        }}
                    >
                        {isEnterprise ? 'Contact us' : props?.isPurchased ? 'Current Package' : `Select`}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Card
