// File hanlder to show the upload file feild as required by default

export const fileFieldHandler = (element) => {
    const requiredCheckbox = element.closest('.form-field').querySelector('.fld-required');
    const asterisk = element.closest('.form-field').querySelector('.required-asterisk');

    if (requiredCheckbox && asterisk) {
        // Set the checkbox to be checked by default
        requiredCheckbox.checked = true;

        // Show the asterisk if the checkbox is checked
        if (requiredCheckbox.checked) {
            asterisk.style.display = 'inline';
        }

        // Add an event listener to handle the asterisk visibility
        requiredCheckbox.addEventListener('change', (e) => {
            if (e.target.checked) {
                asterisk.style.display = 'inline';
            } else {
                asterisk.style.display = 'none';
            }
        });
    }
};
