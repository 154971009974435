import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import Container from '../Container';
import { getLoggedInUserToken } from '../../utils/methods';
import { Menu } from '@mui/icons-material';

const Header = ({ showPlanButton, toggleDrawer }) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                zIndex: 999,
                backgroundColor: '#FFF'
            }}
        >
            <Container>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {getLoggedInUserToken() &&
                            <Box sx={{ cursor: 'pointer' }} onClick={toggleDrawer(true)}>
                                <Menu />
                            </Box >
                        }
                        <Link to={'/dashboard'}>
                            <Box
                                component={'img'}
                                src={logo}
                                alt='barkeep'
                                sx={{
                                    width: 'auto',
                                    height: 52,
                                }}
                            />
                        </Link>
                    </Box>
                    <Stack direction={'row'} alignItems={'center'} gap={1.5}>
                        {showPlanButton && (
                            <Button
                                variant='outlined'
                                size='small'
                                onClick={() => navigate('/')}
                            >
                                Plans
                            </Button>
                        )}
                        {/* show one at a time based on the logged in user */}
                        {getLoggedInUserToken() ?
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => {
                                    localStorage.clear();
                                    navigate('/login')
                                }}
                            >
                                Sign out
                            </Button>
                            :
                            <Button
                                variant='contained'
                                size='medium'
                                onClick={() => navigate('/login')}
                            >
                                Login
                            </Button>
                        }
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

export default Header