import React, { useEffect, useState } from 'react';
import Form from 'rc-field-form';
import { Stack, Box, Button, useMediaQuery } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Input } from '../../../components/Input';
import mainLogo from '../../../assets/main-logo.png';
import { getTeamMemberById, inviteTeamMember, udpateTeamMember } from '../../../utils/http';
import { toast } from 'react-toastify';
import { toastConfiguration } from '../../../utils/contants';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

const InviteMember = ({
    id,
    loading,
    setLoading,
    setSelectedRowId,
    setShowAddNewTeam,
    getUserRegisteredTeam
}) => {
    const isMaxWidth724 = useMediaQuery('(max-width:724px)');
    const isMaxWidth1440 = useMediaQuery('(max-width:1440px)');

    const navigate = useNavigate()
    const [form] = Form.useForm();

    const [formValues, setFormValues] = useState(null);

    useEffect(() => {
        if (id) {
            getSavedTeamMember(id);
        } else {
            setLoading(false);
        }
    }, [id])

    const getSavedTeamMember = async (memberId) => {
        const { data } = await getTeamMemberById(memberId);
        if (data) {
            setFormValues(data?.teamMember);
        }
        setLoading(false);
    }

    const onSubmit = async (values) => {
        const { email, title, employeeName } = values;
        if (email && title && employeeName) {
            setLoading(true);
            let response = null;

            if (id) {
                response = await udpateTeamMember(id, {
                    employeeName,
                    title
                })
            } else {
                response = await inviteTeamMember({
                    email,
                    title,
                    employeeName
                })
            }

            const { data, error } = response;

            if (data) {
                setSelectedRowId(null);
                setShowAddNewTeam(false);
                toast.success(`Team member ${id ? "updated" : "added"} successfully`, toastConfiguration);
                await getUserRegisteredTeam();
            } else {
                toast.error(error || `Error in ${id ? "updating" : "adding"} team member`, toastConfiguration);
            }
            setLoading(false);
        }
    }

    return (
        <Stack alignItems={'center'}>
            <Stack
                alignItems={'flex-start'}
                mb={1}
            >
                <Button
                    startIcon={<ArrowBack fontSize='inherit' />}
                    size='small'
                    onClick={() => {
                        setSelectedRowId(null);
                        setShowAddNewTeam(false)
                    }}
                    sx={{
                        fontSize: 14,
                    }}
                >
                    Back to Teams
                </Button>
            </Stack>
            <Typography variant="h4" fontWeight={700}>
                {(id ? 'Update' : 'Invite') + ' Member'}
            </Typography>
            {(!id || (id && !loading)) && (
                <Form
                    form={form}
                    onFinish={onSubmit}
                    style={{ width: isMaxWidth724 ? '90%' : isMaxWidth1440 ? '50%' : '30%' }}
                >
                    <Grid container spacing={2.5} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <Input
                                label={'Email'}
                                name={'email'}
                                initalValue={formValues?.email}
                                disabled={id ? true : false}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your invite email.'
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please provide valid email!'
                                    }
                                ]}
                                variant="outlined"
                                fullWidth
                            />
                            <Input
                                label={'Full Name'}
                                name={'employeeName'}
                                initalValue={formValues?.employeeName}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input member full name.'
                                    }
                                ]}
                                variant="outlined"
                                sx={{ marginTop: 2 }}
                                fullWidth
                            />
                            <Input
                                sx={{ marginTop: 2 }}
                                label={'Title'}
                                name={'title'}
                                initalValue={formValues?.title}
                                rules={[
                                    {
                                        required: true,
                                        message: `Please input member's title.`
                                    },

                                ]}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton type="submit" fullWidth variant="contained">
                                {(id ? 'Update' : 'Invite') + ' Member'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Stack>
    )
}

export default InviteMember
