import { Field } from 'rc-field-form';
import { TextField } from '@mui/material';

export const Input = ({ name, label, rules, form, min, max, maxCount, initalValue, ...rest }) => (
    <Field name={name} rules={rules} initialValue={initalValue}>
        {(control, meta) => {
            return (<>
                <TextField
                    label={label}
                    error={meta.errors.length > 0}
                    helperText={(maxCount && !meta.errors[0]) ? ((control?.value?.length || 0) + '/' + maxCount) : meta.errors[0]}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (maxCount) {
                            if (value?.length <= maxCount || e.key === 'Backspace') {
                                control.onChange(value);
                            }
                        } else {
                            control.onChange(value);
                        }
                    }}
                    {...control}
                    inputProps={{
                        min: min ? min : null,
                        max: max ? max : null,
                    }}
                    FormHelperTextProps={{
                        sx: (maxCount && !meta.errors[0]) ? {
                            textAlign: 'right'
                        } : null
                    }}
                    {...rest}
                />

            </>);
        }}
    </Field>
);
