import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

function Loader({ open }) {
    return (
        <Backdrop style={{ zIndex: 9999, color: '#FFF' }} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loader;
