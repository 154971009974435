import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledDataGrid } from '../../utils/TableStyle';
import MainLayout from '../../components/MainLayout';
import { deleteTeamMember, getAllTeamMembers } from '../../utils/http';
import Loader from '../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfiguration } from '../../utils/contants';
import InviteMember from './AddNewTeamMember.jsx';

const commonColumnProps = {
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    hideSortIcons: true
}

const columns = [
    {
        field: 'employeeName',
        headerName: 'Full Name',
        ...commonColumnProps
    },
    {
        field: 'title',
        headerName: 'Title',
        resizable: true,
        ...commonColumnProps
    },
    {
        field: 'email',
        headerName: 'Email',
        ...commonColumnProps
    },
    {
        field: 'status',
        headerName: 'Status',
        ...commonColumnProps
    }
];

const NoRowsOverlay = (onClickHandler) => {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={2}
        >
            <Typography sx={{ mb: 2 }}>No team members to display</Typography>
            <Button
                variant='contained'
                size='small'
                onClick={onClickHandler}
            >
                Add Team Member
            </Button>
        </Box>
    );
}

const Teams = () => {
    const [loading, setLoading] = React.useState(true);
    const [showAddNewTeam, setShowAddNewTeam] = React.useState(false);
    const [teams, setTeams] = React.useState([]);
    const [selectedRowId, setSelectedRowId] = React.useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false)

    React.useEffect(() => {
        getUserRegisteredTeam();
    }, [])

    const getUserRegisteredTeam = async () => {
        const { data } = await getAllTeamMembers();
        if (data?.teamMembers) {
            setTeams(data?.teamMembers || []);
        }

        setLoading(false);
    }

    const handleClose = () => {
        setSelectedRowId(null);
        setShowConfirmationModal(false);
    }

    const deleteHandler = async () => {
        setLoading(true);
        setShowConfirmationModal(false);

        const { data, error } = await deleteTeamMember(selectedRowId);
        if (data) {
            await getUserRegisteredTeam();
            toast.success("Team member is deleted successfully", toastConfiguration);
        } else if (error) {
            toast.error(error || "Error in deleting team member", toastConfiguration)
        }

        setSelectedRowId(null);
        setLoading(false);
    }

    return (
        <MainLayout hideUI>
            <ToastContainer />
            <Loader open={loading} />
            <Dialog
                open={showConfirmationModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Team Member?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete team member ? You cannot revert this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={deleteHandler} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {showAddNewTeam ?
                <InviteMember
                    id={selectedRowId}
                    loading={loading}
                    setLoading={setLoading}
                    setSelectedRowId={setSelectedRowId}
                    setShowAddNewTeam={setShowAddNewTeam}
                    getUserRegisteredTeam={getUserRegisteredTeam}
                />
                :
                <>
                    <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} mb={2}>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => setShowAddNewTeam(true)}
                        >
                            Add Team Member
                        </Button>
                    </Box>
                    <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                        <StyledDataGrid
                            slots={{
                                noRowsOverlay: () => NoRowsOverlay(() => setShowAddNewTeam(true)),
                            }}
                            rows={teams}
                            columns={[...(columns || []), {
                                field: 'actions',
                                headerName: 'Actions',
                                renderCell: (params) => (
                                    <Box >
                                        <IconButton
                                            color="primary"
                                            aria-label="edit"
                                            onClick={() => {
                                                setLoading(true);
                                                setSelectedRowId(params.row.id);
                                                setShowAddNewTeam(true);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            aria-label="delete"
                                            onClick={() => {
                                                setSelectedRowId(params.row.id);
                                                setShowConfirmationModal(true);
                                            }}
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ),
                                ...commonColumnProps
                            }]}
                            disableColumnMenu
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 20]}
                            rowHeight={52}
                            columnHeaderHeight={42}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </>
            }
        </MainLayout>
    )
}

export default Teams