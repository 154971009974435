import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledDataGrid } from '../../utils/TableStyle';
import MainLayout from '../../components/MainLayout';
import { deleteQuestionnaire, getAllLocations, getAllQuestionnaires } from '../../utils/http';
import Loader from '../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfiguration } from '../../utils/contants';
import AddNewQuestionnaire from './AddNewQuestionnaire';
import { dateFormatter, sentenceCase, timeFormatter } from '../../utils/methods';
import FormViewer from '../../components/formViewer';
import { useLocation } from 'react-router-dom';

const commonColumnProps = {
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    hideSortIcons: true
}

const columns = [
    {
        field: 'createdAt',
        headerName: 'Date',
        renderCell: (params) => (
            <Box sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography>{dateFormatter(params.row.createdAt)}</Typography>
            </Box>
        ),
        ...commonColumnProps
    },
    {
        field: 'createdAtTime',
        headerName: 'Time',
        renderCell: (params) => (
            <Box sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography>{timeFormatter(params.row.createdAt)}</Typography>
            </Box>
        ),
        ...commonColumnProps
    },
    {
        field: 'location',
        headerName: 'Location',
        resizable: true,
        ...commonColumnProps
    },
    {
        field: 'subLocation',
        headerName: 'Sub Location',
        ...commonColumnProps
    },
    {
        field: 'status',
        headerName: 'Status',
        ...commonColumnProps
    }
];

const NoRowsOverlay = (onClickHandler) => {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={2}
        >
            <Typography sx={{ mb: 2 }}>No forms to display</Typography>
            <Button
                variant='contained'
                size='small'
                onClick={onClickHandler}
            >
                Create Form
            </Button>
        </Box>
    );
}

const Questionnaire = () => {
    const location = useLocation();
    const { state } = location;

    const [loading, setLoading] = React.useState(true);
    const [locations, setLocations] = React.useState([]);
    const [showAddNewQuestionnaire, setShowAdddNewQuestionnaire] = React.useState(false);
    const [questionnaires, setQuestionnaires] = React.useState([]);
    const [selectedRowId, setSelectedRowId] = React.useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false)
    const [showFormViewerModal, setShowFormViewerModal] = React.useState(false);

    React.useEffect(() => {
        if (state?.showCreate) setShowAdddNewQuestionnaire(true)
        getAllUserLocations();
        getUserRegistredQuestionnaires();
    }, [])

    const getAllUserLocations = async () => {
        const { data } = await getAllLocations();
        if (data?.locations) {
            setLocations(data?.locations || []);
        }
    }

    const getUserRegistredQuestionnaires = async () => {
        const { data } = await getAllQuestionnaires();
        if (data?.questionnaires) {
            const dbQuestionnaires = data?.questionnaires?.map(item => {
                return {
                    ...item,
                    id: item._id,
                    location: sentenceCase(item?.location),
                    subLocation: sentenceCase(item?.subLocation),
                    status: sentenceCase(item?.status)
                }
            })
            setQuestionnaires(dbQuestionnaires || []);
        }

        setLoading(false);
    }

    const handleClose = () => {
        setSelectedRowId(null);
        setShowConfirmationModal(false);
    }

    const deleteHandler = async () => {
        setLoading(true);
        setShowConfirmationModal(false);

        const { data, error } = await deleteQuestionnaire(selectedRowId);
        if (data) {
            await getUserRegistredQuestionnaires();
            toast.success("Questionnaire is deleted successfully", toastConfiguration);
        } else if (error) {
            toast.error(error || "Error in deleting questionnaire", toastConfiguration)
        }

        setSelectedRowId(null);
        setLoading(false);
    }

    return (
        <MainLayout hideUI>
            <ToastContainer />
            <Loader open={loading} />

            <Dialog
                open={showFormViewerModal}
                onClose={() => {
                    setSelectedRowId(null);
                    setShowFormViewerModal(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Questions"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormViewer id={selectedRowId} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setSelectedRowId(null);
                        setShowFormViewerModal(false);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showConfirmationModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Questionnaire?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete questionnaire ? You cannot revert this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={deleteHandler} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {showAddNewQuestionnaire ?
                <AddNewQuestionnaire
                    id={selectedRowId}
                    loading={loading}
                    locations={locations}
                    setLoading={setLoading}
                    setSelectedRowId={setSelectedRowId}
                    setShowAdddNewQuestionnaire={setShowAdddNewQuestionnaire}
                    getUserRegistredQuestionnaires={getUserRegistredQuestionnaires}
                />
                :
                <>
                    <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} mb={2}>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => setShowAdddNewQuestionnaire(true)}
                        >
                            Create Form
                        </Button>
                    </Box>
                    <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                        <StyledDataGrid
                            slots={{
                                noRowsOverlay: () => NoRowsOverlay(() => setShowAdddNewQuestionnaire(true)),
                            }}
                            rows={questionnaires}
                            columns={[...(columns || []), {
                                field: 'questions',
                                headerName: 'Questions',
                                renderCell: (params) => (
                                    <Box
                                        onClick={() => {
                                            setSelectedRowId(params.row.id);
                                            setShowFormViewerModal(true);
                                        }}
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: 'primary.main'
                                        }}>
                                        <Typography>view questions</Typography>
                                    </Box>
                                ),
                                ...commonColumnProps
                            }, {
                                field: 'actions',
                                headerName: 'Actions',
                                renderCell: (params) => (
                                    <Box >
                                        <IconButton
                                            color="primary"
                                            aria-label="edit"
                                            onClick={() => {
                                                setLoading(true);
                                                setSelectedRowId(params.row.id);
                                                setShowAdddNewQuestionnaire(true);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            aria-label="delete"
                                            onClick={() => {
                                                setSelectedRowId(params.row.id);
                                                setShowConfirmationModal(true);
                                            }}
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ),
                                ...commonColumnProps
                            }]}
                            disableColumnMenu
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 20]}
                            rowHeight={52}
                            columnHeaderHeight={42}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </>
            }
        </MainLayout>
    )
}

export default Questionnaire;