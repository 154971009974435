import * as React from 'react';
import { Box,Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { StyledDataGrid } from '../../utils/TableStyle';
import MainLayout from '../../components/MainLayout';
import { dateFormatter, sentenceCase } from '../../utils/methods';
import { getAllResponses } from '../../utils/http';
import Loader from '../../components/Loader';
import FormViewer from '../../components/formViewer';
import { useState } from 'react';
import ImageButtonWithModal from '../../components/ImageModel';

const commonColumnProps = {
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    hideSortIcons: true
}

const startColumns = [
    {
        field: 'responseByName',
        headerName: 'Server',
        ...commonColumnProps
    },
    {
        field: 'responseBy',
        headerName: 'Email',
        ...commonColumnProps
    },
    {
        field: 'status',
        headerName: 'Status',
        ...commonColumnProps
    },
    {
        field: 'location',
        headerName: 'Location',
        ...commonColumnProps
    },
    {
        field: 'subLocation',
        headerName: 'SubLocation',
        ...commonColumnProps
    },
    {
        field: 'createdAt',
        headerName: 'Date',
        renderCell: (params) => (
            <Box sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography>{dateFormatter(params.row.createdAt)}</Typography>
            </Box>
        ),
        ...commonColumnProps
    }]

    const endColumns = [{
        field: 'images',
        headerName: 'Shots',
        renderCell: (params) => (
            <ImageButtonWithModal images={params.row.images || []} />
        ),
        ...commonColumnProps

},
{
    field: 'signature',
    headerName: 'Sign',
    renderCell: (params) => (
        <Box
            component={'img'}
            src={params.row.signature}
            alt='sign'
            sx={{
                mt: 0.5,
                height: 42,
                width: 'auto',
            }}
        />
    ),
    ...commonColumnProps
},
];

const Dashboard = () => {
    const [loading, setLoading] = React.useState(true);
    const [showFormViewerModal, setShowFormViewerModal] = React.useState(false);
    const [selectedRowId, setSelectedRowId] = React.useState(null);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        getAllUserResponses();
    }, [])

    const getAllUserResponses = async () => {
        const { data } = await getAllResponses();
        if (data?.responses) {
            const dbResponses = data?.responses?.map(item => {
                return {
                    ...item,
                    id: item._id,
                    location: sentenceCase(item?.location),
                    subLocation: sentenceCase(item?.subLocation),
                    status: sentenceCase(item?.status)
                }
            })
            setRows(dbResponses || []);
        }

        setLoading(false);
    }


    return (
        <MainLayout hideUI>
            <Dialog
                open={showFormViewerModal}
                onClose={() => {
                    setSelectedRowId(null);
                    setShowFormViewerModal(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Response"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormViewer id={selectedRowId} isResponse />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setSelectedRowId(null);
                        setShowFormViewerModal(false);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Loader open={loading} />
            <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                <StyledDataGrid
                    rows={rows}
                    columns={[...startColumns, {
                        field: 'response',
                        headerName: 'Responses',
                        renderCell: (params) => (
                            <Box
                                onClick={() => {
                                    setSelectedRowId(params.row.id);
                                    setShowFormViewerModal(true);
                                }}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    color: 'primary.main'
                                }}>
                                <Typography>view response</Typography>
                            </Box>
                        ),
                        ...commonColumnProps
                    }, ...endColumns]}
                    disableColumnMenu
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    rowHeight={52}
                    columnHeaderHeight={42}
                    disableRowSelectionOnClick
                />
            </Box>
        </MainLayout>
    )
}

export default Dashboard