import React from 'react';
import { Stack, Typography } from '@mui/material';
import MainLayout from '../../components/MainLayout';

const NotSubscribed = () => {
    return (
        <MainLayout showPlanButton={true} removePadding>
            <Stack gap={8} alignItems={'center'} justifyContent={'center'} height={'100vh'}>
                <Typography variant="h4" fontWeight={500} sx={{ color: 'primary.main' }}>
                    Let's buy out a plan and start quickly !!!
                </Typography>
            </Stack>
        </MainLayout>
    )
}

export default NotSubscribed