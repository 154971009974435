// handleCheckboxGroup.js
export const handleCheckboxGroup = (element) => {
    const addOptionButton = element.querySelector('.add.add-opt');
    const listItems = element.querySelectorAll('li');

    listItems.forEach((li, index) => {
        const input = document.createElement('input');
        input.value = `value-${index}`;
        input.type = 'checkbox';
        input.setAttribute('data-attr', 'selected');
        input.className = 'reason-option-x option-selected option-attr';
        li.appendChild(input);
        const span = document.createElement('span');
        span.innerHTML = 'Get Reason';
        span.className = 'option-selected option-attr';
        li.appendChild(span);
    });

    if (addOptionButton) {
        addOptionButton.addEventListener('click', () => {
            setTimeout(() => {
                const newListItems = element.querySelectorAll('li');
                if (newListItems.length > 0) {
                    const lastItem = newListItems[newListItems.length - 1];
                    const input = document.createElement('input');
                    input.value = `value-${newListItems.length}`;
                    input.type = 'checkbox';
                    input.setAttribute('data-attr', 'selected');
                    input.className = 'reason-option-x option-selected option-attr';
                    lastItem.appendChild(input);
                    const span = document.createElement('span');
                    span.innerHTML = 'Get Reason';
                    span.className = 'option-selected option-attr';
                    lastItem.appendChild(span);
                }
            }, 100);
        });
    }
};
