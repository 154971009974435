import { ArrowBack } from "@mui/icons-material";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography, useMediaQuery } from "@mui/material";
import $ from "jquery";
import React, { createRef, useEffect, useState } from "react";
import MainLayout from "../../../components/MainLayout";
import { LoadingButton } from "@mui/lab";
import { addNewQuestionnaire, getQuestionnaireById, updateExistingQuestionnaire } from "../../../utils/http";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../utils/contants";
import { handleSelect } from "../../../components/SelectGetReason";
import { handleCheckboxGroup } from "../../../components/CheckboxGetReason";
import {fileFieldHandler} from "../../../components/FileFieldHandler"

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const options = {
    disableFields: ['autocomplete', 'button', 'paragraph', 'date', 'header', 'hidden', 'number', 'radio-group','text'],
    controlOrder: [
        'checkbox-group', 'select','textarea', 'file'
    ],
    showActionButtons: false,
    onOpenFieldEdit: () => {
        $(`
        .description-wrap,
        .toggle-wrap,
        .inline-wrap,
        .className-wrap,
        .name-wrap,
        .access-wrap,
        .other-wrap,
        .value-wrap,
        .subtype-wrap,
        .maxlength-wrap,
        .rows-wrap,
        .multiple-wrap
        `).hide();
    },
    
    onAddField: (fieldId) => {
        if (fieldId) {
            const parts = fieldId?.split('-')
            const lastPart = parts?.pop();
            const number = parseInt(lastPart?.split('-')?.pop(), 10);
            const decrementedNumber = number + 1;
            parts.push(decrementedNumber)
            const id = parts?.join('-');

            setTimeout(() => {
                const element = document.getElementById(id);
                if (element && element.getAttribute('type') === 'select') {
                    handleSelect(element, id);
                }
                else if (element.getAttribute('type') === 'checkbox-group') {
                    handleCheckboxGroup(element);
                }
                else if (element.getAttribute('type') === 'file') {
                    fileFieldHandler(element);
                }
            }, 100);
        }
    }
};

let formBuilder = null;
const AddNewQuestionnaire = ({
    id,
    locations,
    loading,
    setLoading,
    setSelectedRowId,
    setShowAdddNewQuestionnaire,
    getUserRegistredQuestionnaires
}) => {
    const [status, setStatus] = useState('select');
    const [location, setLoction] = useState('select');
    const [subLocation, setSubLocation] = useState('select');

    const fb = createRef();
    const isMaxWidth724 = useMediaQuery('(max-width:724px)');
    const isMaxWidth1440 = useMediaQuery('(max-width:1440px)');

    useEffect(() => {
        if (id) {
            getSavedQuestionnaire(id);
        } else {
            formBuilder = $(fb.current).formBuilder(options);
            setLoading(false);
        }
    }, [id])

    const getSavedQuestionnaire = async (questionnaireId) => {
        const { data } = await getQuestionnaireById(questionnaireId);
        if (data?.questionnaire) {
            const parsedData = JSON.parse(data?.questionnaire?.questions);
            parsedData?.map(item => {
                if (item.type === 'select' || item.type === 'checkbox-group') {
                    item?.values?.map(value => {
                        delete value?.getReason; // Clear existing getReason data
                    });
                }
            });
            const updatedStringifiedData = JSON.stringify(parsedData);
            const optionsWithData = {
                ...options,
                formData: updatedStringifiedData,
            };
            setLoction(data?.questionnaire?.location);
            setSubLocation(data?.questionnaire?.subLocation);
            setStatus(data?.questionnaire?.status);
            formBuilder = $(fb.current).formBuilder(optionsWithData);
    
            setTimeout(() => {
                const parsedData = JSON.parse(data?.questionnaire?.questions);
                settingGetReasonBullet(parsedData, true);
            }, 500);
        }
        setLoading(false);
    }
    

    const saveHandler = async () => {
        let formData = formBuilder?.actions?.getData('json');
        //VALIDATIONS

        if (location === 'select') {
            toast.error("Please select any location", toastConfiguration);
            return;
        }

        if (subLocation === 'select') {
            toast.error("Please select any sub-location", toastConfiguration);
            return;
        }

        if (status === 'select') {
            toast.error("Please select any status", toastConfiguration);
            return;
        }

        if (JSON.parse(formData || [])?.length < 1) {
            toast.error("Form should have atleast one question", toastConfiguration);
            return;
        }

        setLoading(true);

        const parsedFormData = JSON.parse(formData);
        settingGetReasonBullet(parsedFormData, false);

        setLoading(false);

        formData = JSON.stringify(parsedFormData);

        const payload = {
            location,
            subLocation,
            status,
            questions: formData
        }
        let response = null;

        if (id) {
            response = await updateExistingQuestionnaire(id, payload)
        } else {
            response = await addNewQuestionnaire(payload)
        }

        const { data, error } = response;

        if (data) {
            setSelectedRowId(null);
            setShowAdddNewQuestionnaire(false);
            toast.success(`Questionnaire ${id ? "updated" : "added"} successfully`, toastConfiguration);
            await getUserRegistredQuestionnaires();
        } else {
            toast.error(error || `Error in ${id ? "updating" : "adding"} questionnaire`, toastConfiguration);
        }
        setLoading(false);
    }


    const settingGetReasonBullet = (data, setter) => {
        data?.map(item => {
            if (item.type === "select") {
                console.log(document.querySelectorAll('[name]'));
                const element = document.querySelector(`[name=${item.name}-preview]`);
                const parentLi = element.closest('li');
                if (parentLi) {
                    const selectOptions = parentLi.querySelectorAll('.ui-sortable-handle');
                    if (selectOptions && selectOptions[0]) {
                        const parentElement = selectOptions[0]?.parentElement;
                        if (parentElement) {
                            const childrens = parentElement?.children;
                            for (let i = 0; i < childrens?.length; i++) {
                                const reasonBullet = childrens[i]?.querySelector('.reason-option-x');
                                if (reasonBullet && item?.values[i]) {
                                    if (setter) {
                                        reasonBullet.checked = item.values[i].getReason;
                                    } else {
                                        item.values[i].getReason = reasonBullet.checked;
                                    }
                                }
                            }
                        }
                    } 
                }
            }

            else  if (item.type === "checkbox-group") {
                const element = document.querySelector(`[name="${item.name}-preview[]"]`);
                if (element) {
                    const parentLi = element.closest('li');
                    if (parentLi) {
                        const options = parentLi.querySelectorAll('.ui-sortable-handle');
                        if (options.length > 0) {
                            const parentElement = options[0]?.parentElement;
                            if (parentElement) {
                                const children = parentElement?.children;
                                for (let i = 0; i < children?.length; i++) {
                                    const checkbox = children[i]?.querySelector('input[type="checkbox"]');
                                    const reasonBullet = children[i]?.querySelector('.reason-option-x');
                                    if (checkbox && item?.values[i]) {
                                        if (setter) {
                                            // Set checkbox state
                                            checkbox.checked = item.values[i].selected || false;
    
                                            // Set reasonBullet state
                                            if (reasonBullet) {
                                                reasonBullet.checked = item.values[i].getReason || false;
                                            }
                                        } else {
                                            // Update item.values based on checkbox and reasonBullet
                                            item.values[i].selected = checkbox.checked;
                                            item.values[i].getReason = reasonBullet ? reasonBullet.checked : false;
                                        }
                                    } else if (item.values[i] && !item.values[i].hasOwnProperty('getReason')) {
                                        // Initialize getReason if not present
                                        item.values[i].getReason = false;
                                    }
                                }
                            } 
                        } 
                    } 
                }
            }
        });
    }


    return <Stack alignItems={'center'} width={'100%'}>
        <Stack
            alignItems={'flex-start'}
            mb={1}
        >
            <Button
                startIcon={<ArrowBack fontSize='inherit' />}
                size='small'
                onClick={() => {
                    setSelectedRowId(null);
                    setShowAdddNewQuestionnaire(false)
                }}
                sx={{
                    fontSize: 14,
                }}
            >
                Back to Form
            </Button>
        </Stack>

        <Typography variant="h4" fontWeight={700}>
            {(id ? 'Update' : 'Add') + ' Form'}
        </Typography>

        {/* FORMS */}

        <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            sx={{ marginTop: 4, width: isMaxWidth724 ? '90%' : isMaxWidth1440 ? '50%' : '30%' }}
        >
            <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={location}
                onChange={(e) => {
                    setSubLocation('select')
                    setLoction(e.target.value)
                }}
                label="Location"
            >
                <MenuItem value="select">
                    <em>Select</em>
                </MenuItem>
                {locations?.map((item, index) => {
                    return <MenuItem key={index} value={item.location}>{item.location}</MenuItem>
                })}
            </Select>
        </FormControl>

        <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            sx={{ width: isMaxWidth724 ? '90%' : isMaxWidth1440 ? '50%' : '30%' }}
        >
            <InputLabel id="demo-simple-select-outlined-label">Sub Location</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subLocation}
                onChange={(e) => setSubLocation(e.target.value)}
                label="SubLocation"
            >
                <MenuItem value="select">
                    <em>Select</em>
                </MenuItem>
                {locations?.filter((item) => item.location === location)?.map((x) => {
                    return x?.subLocations?.map((singleSubLocation, index) => {
                        return <MenuItem key={index} value={singleSubLocation}>{singleSubLocation}</MenuItem>
                    })
                })}
            </Select>
        </FormControl>

        <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            sx={{ width: isMaxWidth724 ? '90%' : isMaxWidth1440 ? '50%' : '30%' }}
        >
            <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
            >
                <MenuItem value="select">
                    <em>Select</em>
                </MenuItem>
                <MenuItem value={'opening'}>Opening</MenuItem>
                <MenuItem value={'closing'}>Closing</MenuItem>
            </Select>
        </FormControl>


        <Box sx={{ mt: 4, width: '100%' }}>
            <div id="fb-editor" ref={fb} />
        </Box>
        <Box sx={{ mt: 2, pb: 8 }} onClick={saveHandler}>
            <LoadingButton fullWidth variant="contained">
                {id ? 'Update' : 'Add'}
            </LoadingButton>
        </Box>
    </Stack>
}

export default AddNewQuestionnaire;