import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Stack, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AuthLayout = ({ backButton, backButtonText, backbuttonRedirection, children }) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100%',
                minHeight: { xs: '100svh', lg: '100vh' },
                position: 'relative',
                // background: 'linear-gradient(150deg, rgba(255,255,255,0) 45%, #ffdcdd 45%, #ffdcdd 49%, rgba(255,255,255,0) 49%, rgba(255,255,255,0) 51%, #ffdcdd 51%, #ffdcdd 55%, rgba(255,255,255,0) 55%)',
                backgroundColor: '#FFFFFF',
                isolation: 'isolate',
            }}
        >
            <Stack
                sx={{
                    p: 3,
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: { xs: '100svh', lg: '100vh' },
                }}
            >
                {backButton ? (
                    <Stack
                        alignItems={'flex-start'}
                        mb={1}
                        sx={{
                            maxWidth: 450,
                            width: '100%',
                            mx: 'auto',
                        }}
                    >
                        <Button
                            startIcon={<ArrowBackIcon fontSize='inherit' />}
                            size='small'
                            onClick={() => navigate(backbuttonRedirection || '/')}
                            sx={{
                                fontSize: 14,
                            }}
                        >
                            {backButtonText || "Back to dashboard"}
                        </Button>
                    </Stack>
                ) : null}
                <Card
                    sx={{
                        borderRadius: 3,
                        maxWidth: 450,
                        width: '100%',
                        mx: 'auto',
                    }}
                >
                    <CardContent sx={{ p: 4 }}>
                        {children}
                    </CardContent>
                </Card>
            </Stack>
        </Box>
    )
}

export default AuthLayout
