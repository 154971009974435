import { DataGrid } from '@mui/x-data-grid';
import { styled, alpha } from '@mui/material/styles';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    backgroundColor: '#fff',
    // ".MuiDataGrid-row": {
    //     border: 'none',
    //     '.MuiDataGrid-cell': {
    //         borderRight: '1px solid',
    //         borderColor: theme.palette.divider,
    //     }
    // },
    ".MuiDataGrid-columnHeaders": {
        ".MuiDataGrid-columnHeader": {
            backgroundColor: '#ff5055',
            color: '#fff',
            '.MuiButtonBase-root': {
                color: '#fff',
            },
            '&:hover': {
                '.MuiDataGrid-columnSeparator': {
                    color: '#fff',
                },
            },
            // borderRight: '1px solid',
            // borderColor: theme.palette.divider,
            // '&[data-field="__reorder__"]': {
            //     display: 'none'
            // },
            textTransform: 'capitalize',
            ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
            }
        }
    }
}));