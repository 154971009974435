

import $ from "jquery";
import { Box } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { getQuestionnaireById, getResponseById } from "../utils/http";
import Loader from "./Loader";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const options = {
    disableFields: ['autocomplete', 'button', 'paragraph', 'date', 'header', 'hidden', 'number', 'radio-group'],
    controlOrder: [
        'checkbox-group', 'select', 'text', 'textarea', 'file'
    ],
    showActionButtons: false
};

const FormViewer = ({ id, isResponse }) => {
    const [loading, setLoading] = useState(true);
    const fb = createRef();

    useEffect(() => {
        if (id) {
            getSavedQuestionnaire(id);
        }
    }, [id]);

    const getSavedQuestionnaire = async (questionnaireId) => {
        const { data } = isResponse ? await getResponseById(questionnaireId) :
            await getQuestionnaireById(questionnaireId);
        if (data?.questionnaire || data?.response?.response) {

            // Exclude type file
            if (data?.response?.response) {
                let jsonData = JSON.parse(data?.response?.response || "[]");
                if (jsonData?.length > 0) {
                    jsonData = jsonData?.filter(item => item.type !== 'file')?.map(item => {
                        if (item.type === 'select') {
                            delete item.placeholder
                        }
                        return item
                    });
                    data.response.response = JSON.stringify(jsonData);
                }
            }

            const optionsWithData = {
                ...options,
                formData: data?.questionnaire?.questions || data?.response?.response,
            };

            $(fb.current).formBuilder(optionsWithData);

            // Apply styles after the form has been initialized
            setTimeout(() => {
                applyCustomStyles(); 
            }, 1000); // Adjust timeout as needed
        }
        setLoading(false);
    };

    const applyCustomStyles = () => {
        // Apply custom styles to make the textarea larger and ensure the scrollbar is touchable
        $('#fb-editor-hidden textarea').each(function () {
            $(this).css({
                'resize': 'vertical',
                'height': '100px', // Adjust height as needed
                'width': '100%',
                'overflow-y': 'auto', // Vertical scrollbar for textarea
                'box-sizing': 'border-box'
            });
        });
    
       // Select the specific input field by its ID
        $('#fb-editor-hidden #reason-preview').each(function () {
            // Replace the input field with a textarea and transfer the value
            $(this).replaceWith(function () {
                return $('<textarea>', {
                    'name': $(this).attr('name'),
                    'id': $(this).attr('id'),
                    'class': $(this).attr('class'),
                    'css': {
                        'resize': 'vertical',
                        'height': '100px', // Adjust height as needed
                        'width': '100%',
                        'overflow-y': 'auto', // Vertical scrollbar
                        'box-sizing': 'border-box'
                    }
                }).text($(this).val()); // Set the textarea content
            });
        });
    
        // Ensure proper overflow handling
        $('#fb-editor-hidden').css({
            'overflow': 'hidden',
            'position': 'relative',
            'height': '100%',
        });
    
        // Adjust the form builder container to handle scrolls properly
        $('.form-builder-container').css({
            'overflow': 'auto',
            'max-height': 'calc(100vh - 200px)',
            'position': 'relative',
            'pointer-events': 'auto'
        });
    };
    

    return (
        <Box sx={{ mt: 2, width: '100%', pointerEvents: 'auto', overflow: 'hidden', maxHeight: 'auto' }}>
            <Loader open={loading} />
            <div id="fb-editor-hidden" ref={fb} />
        </Box>
    );
};

export default FormViewer;