import React, { useState } from 'react';
import Form from 'rc-field-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Stack, Box } from '@mui/material';
import { Grid, InputAdornment, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Input } from '../../components/Input';
import AuthLayout from '../../components/AuthLayout';
import mainLogo from '../../assets/main-logo.png';
import { signUpHandler } from '../../utils/http';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfiguration } from '../../utils/contants';
import Loader from '../../components/Loader';

const Signup = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [confShowPassword, setConfShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickConfShowPassword = () => setConfShowPassword((show) => !show);

    const onSubmit = async (values) => {

        const { username, password } = values;
        const planKey = state?.planType;

        if (!planKey) {
            toast.error("Please select any plan before continue", toastConfiguration);
            return;
        }

        if (username && password) {
            setLoading(true);
            const { data, error } = await signUpHandler({
                email: username,
                selectedPackage: planKey,
                password
            })
            if (data) {
                navigate("/email-verification", { state: { email: username } });
            } else if (error) {
                toast.error(error || "Error in signing up", toastConfiguration);
            }
            setLoading(false);
        }
    }

    return (
        <AuthLayout backButton={true} backButtonText={'Back to plans'}>
            <Loader open={loading} />
            <ToastContainer />
            <Stack alignItems={'center'}>
                <Box
                    component={'img'}
                    src={mainLogo}
                    alt='barkeep'
                    sx={{
                        maxWidth: 200,
                        width: '100%',
                        mx: 'auto',
                    }}
                />
                <Typography variant="h4" fontWeight={700}>
                    Signup
                </Typography>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    style={{ width: '100%' }}
                >
                    <Grid container spacing={2.5} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <Input
                                label={'Email'}
                                name={'username'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email.'
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please provide valid email!'
                                    }
                                ]}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label={'Password'}
                                name={'password'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Password is required.'
                                    },

                                ]}
                                variant="outlined"
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                size='small'
                                                edge={'end'}
                                            >
                                                {showPassword
                                                    ? <VisibilityOffOutlinedIcon fontSize='small' />
                                                    : <VisibilityOutlinedIcon fontSize='small' />
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label={'Confirm Password'}
                                name={'confirm_password'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Confirm is required.'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The confirm password that you entered do not match!'));
                                        },
                                    }),

                                ]}
                                variant="outlined"
                                fullWidth
                                type={confShowPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle confirm password visibility"
                                                onClick={handleClickConfShowPassword}
                                                size='small'
                                                edge={'end'}
                                            >
                                                {confShowPassword
                                                    ? <VisibilityOffOutlinedIcon fontSize='small' />
                                                    : <VisibilityOutlinedIcon fontSize='small' />
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton type="submit" fullWidth variant="contained">
                                Signup
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            </Stack>
        </AuthLayout>
    )
}

export default Signup
