import React, { useEffect, useState } from 'react';
import { Stack, Box, Grid, useMediaQuery, Button, IconButton } from '@mui/material';
import { Typography } from '@mui/material';
import Form from 'rc-field-form';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input } from '../../../components/Input';
import { addLocation, getLocationById, updateLocation } from '../../../utils/http';
import { toast } from 'react-toastify';
import { toastConfiguration } from '../../../utils/contants';
import { ArrowBack } from '@mui/icons-material';

const AddNewLocation = ({
    id,
    setLoading,
    loading,
    setSelectedRowId,
    setShowAddNewLocation,
    getUserRegisteredLocations
}) => {
    const [subLocations, setSubLocations] = useState(['location0']);
    const [formValues, setFormValues] = useState(null);
    const [form] = Form.useForm();

    const isMaxWidth1200 = useMediaQuery('(max-width:1200px)');
    const isMaxWidth724 = useMediaQuery('(max-width:724px)');

    useEffect(() => {
        if (id) {
            getSavedLocation(id);
        } else {
            setLoading(false);
        }
    }, [id])

    const getSavedLocation = async (locationId) => {
        const { data } = await getLocationById(locationId);
        if (data) {
            setSubLocations(data?.location?.subLocations || subLocations);
            setFormValues(data?.location);
        }
        setLoading(false);
    }

    const onSubmit = async (values) => {
        setLoading(true);
        let addedSubLocations = [];

        Object.keys(values)?.map(item => {
            if (item?.includes("subLocation")) {
                addedSubLocations.push(values[item])
            }
        })

        const payload = { ...values, subLocations: addedSubLocations };
        let response = null;
        if (id) {
            response = await updateLocation(id, payload);
        } else {
            response = await addLocation(payload);
        }

        const { data, error } = response;

        if (data) {
            setSelectedRowId(null);
            setShowAddNewLocation(false);
            toast.success(`Location ${id ? "updated" : "added"} successfully`, toastConfiguration);
            await getUserRegisteredLocations();
        } else {
            toast.error(error || `Error in ${id ? "updating" : "adding"} location`, toastConfiguration);
        }

        setLoading(false);
    }

    return (
        <Stack alignItems={'center'} pb={8}>
            <Stack
                alignItems={'flex-start'}
                mb={1}
            >
                <Button
                    startIcon={<ArrowBack fontSize='inherit' />}
                    size='small'
                    onClick={() => {
                        setSelectedRowId(null);
                        setShowAddNewLocation(false)
                    }}
                    sx={{
                        fontSize: 14,
                    }}
                >
                    Back to Locations
                </Button>
            </Stack>

            <Typography variant="h4" fontWeight={700}>
                {id ? "Edit Location" : "Setup Loctation"}
            </Typography>
            {(!id || (id && !loading)) && (
                <Form
                    form={form}
                    onFinish={onSubmit}
                    style={{ width: isMaxWidth1200 ? '100%' : '70%' }}
                >
                    <Grid container spacing={2.5} sx={isMaxWidth724 ? { mt: 1, flexDirection: 'column', alignItems: 'center' } : { mt: 1 }} >
                        <Grid item xs={isMaxWidth724 ? 12 : 5}>
                            <Input
                                label={'Location'}
                                name={'location'}
                                initalValue={formValues?.location}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add location.'
                                    }
                                ]}
                                variant="outlined"
                                sx={{ marginTop: 2 }}
                                fullWidth
                            />
                            {subLocations?.map((item, index) => {
                                return <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                    <Input
                                        key={index}
                                        label={`Sub Location ${index + 1}`}
                                        name={`subLocation${index}`}
                                        initalValue={(id && (subLocations?.length === formValues?.subLocations?.length)) ? item : ''}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please add sub-location.'
                                            }
                                        ]}
                                        variant="outlined"
                                        sx={{ marginTop: 2 }}
                                        fullWidth
                                    />
                                    {(subLocations?.length > 1 && (subLocations?.length === (index + 1))) && (
                                        <IconButton
                                            color="secondary"
                                            aria-label="delete"
                                            onClick={() => {
                                                form.setFieldsValue({ [`subLocation${index}`]: null });
                                                subLocations?.splice(index, 1);
                                                setSubLocations([...(subLocations || [])]);
                                            }}
                                            sx={{
                                                mt: 2,
                                                color: 'primary.main'
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            })}

                            <LoadingButton
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ width: isMaxWidth1200 ? '100%' : 234, mt: 2 }}
                                onClick={() => setSubLocations([...subLocations, `location${(subLocations?.length + 1)}`])}
                            >
                                Add Sub Location
                            </LoadingButton>

                            <Input
                                label={'Address'}
                                name={'address'}
                                initalValue={formValues?.address}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add address.'
                                    }
                                ]}
                                variant="outlined"
                                sx={{ marginTop: 8 }}
                                fullWidth
                            />
                            <Input
                                label={'City'}
                                name={'city'}
                                initalValue={formValues?.city}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add city.'
                                    }
                                ]}
                                variant="outlined"
                                sx={{ marginTop: 2 }}
                                fullWidth
                            />
                            <Input
                                label={'State'}
                                name={'state'}
                                initalValue={formValues?.state}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add state.'
                                    },
                                    {
                                        validator: (_, value) => {
                                            const regex = /^[A-Za-z]*$/;; 
                                            if (!value || regex.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Input should contain alpha characters'));
                                        }
                                    }
                                ]}
                                variant="outlined"
                                sx={{ marginTop: 2 }}
                                fullWidth
                            />
                            <Input
                                label={'Zip Code'}
                                name={'zipCode'}
                                inputProps={{ type: 'number' }}
                                initalValue={formValues?.zipCode}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add zip code.'
                                    }
                                ]}
                                variant="outlined"
                                sx={{
                                    mt: 2,
                                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                    },
                                    "input[type=number]": {
                                        MozAppearance: "textfield",
                                    },
                                }}
                                fullWidth
                            />
                        </Grid>
                        {isMaxWidth724 ? null :
                            <Grid item xs={2} />
                        }
                        <Grid item xs={isMaxWidth724 ? 12 : 5} display={'flex'} alignItems={'flex-start'} sx={{ width: '100%' }}>
                            <Box width={'100%'} display={'flex'} alignItems={'flex-end'} flexDirection={'column'}>
                                <Input
                                    label={'Notes about this location'}
                                    name={'notes'}
                                    initalValue={formValues?.notes}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please add notes.'
                                        }
                                    ]}
                                    variant="outlined"
                                    sx={{ marginTop: 2 }}
                                    fullWidth
                                    multiline
                                    rows={5}
                                />
                                <LoadingButton type="submit" fullWidth variant="contained" sx={{ width: isMaxWidth724 ? '100%' : 120, mt: 2 }}>
                                    {id ? "Update" : "Save"}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Stack>
    )
}

export default AddNewLocation;
