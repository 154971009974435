import * as React from 'react';
import { Box } from '@mui/material';
import Container from '../Container';
import Header from './Header';
import logoIcon from '../../assets/icon.png';
import Sidebar from './Sidebar';



const MainLayout = ({ children, hideUI, showPlanButton, removePadding, hideImage }) => {
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100%',
                minHeight: { xs: '100svh', lg: '100vh' },
                position: 'relative',
                background: !hideUI && 'linear-gradient(150deg, rgba(255,255,255,0) 45%, #ffdcdd 45%, #ffdcdd 49%, rgba(255,255,255,0) 49%, rgba(255,255,255,0) 51%, #ffdcdd 51%, #ffdcdd 55%, rgba(255,255,255,0) 55%)',
                backgroundColor: '#FFFFFF',
                isolation: 'isolate',
                paddingTop: removePadding ? '0px' : '100px',
            }}
        >
            <Sidebar open={open} toggleDrawer={toggleDrawer} />
            <Header toggleDrawer={toggleDrawer} showPlanButton={showPlanButton}/>
            <Container>
                {children}
            </Container>
            {hideUI || hideImage ? null : (
                <Box
                    component={'img'}
                    src={logoIcon}
                    alt='barkeep'
                    sx={{
                        position: 'absolute',
                        right: '-2%',
                        bottom: '-5%',
                        height: '35%',
                        zIndex: -1,
                    }}
                />
            )}
        </Box>
    )
}

export default MainLayout
