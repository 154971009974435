import React, { useEffect, useState } from 'react';
import { Stack, Box } from '@mui/material';
import { Typography } from '@mui/material';
import AuthLayout from '../../components/AuthLayout';
import mainLogo from '../../assets/main-logo.png';
import OtpInput from 'react-otp-input';
import { emailVerificationHandler } from '../../utils/http';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfiguration } from '../../utils/contants';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import { stripeHandler } from '../../utils/methods';

const EmailVerification = () => {
    const { state } = useLocation();

    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (otp?.length === 4) {
            setIsDisabled(true);
            verifyEmailVerificationCode();
        }
    }, [otp])

    const verifyEmailVerificationCode = async () => {
        setLoading(true);
        const { data, error } = await emailVerificationHandler({
            email: state?.email,
            token: otp
        })

        if (data) {
            localStorage.setItem("token", data?.token);
            localStorage.setItem("isSubscribed", data?.isSubscribed);
            await stripeHandler(data?.currentPackage);
        } else if (error) {
            toast.error(error || "Error in validating email code", toastConfiguration);
        }

        setIsDisabled(false);
        setLoading(false);
    }

    return (
        <AuthLayout backButton={true} backButtonText={'Back to plans'}>
            <Loader open={loading} />
            <ToastContainer />
            <Stack alignItems={'center'}>
                <Box
                    component={'img'}
                    src={mainLogo}
                    alt='barkeep'
                    sx={{
                        maxWidth: 200,
                        width: '100%',
                        mx: 'auto',
                    }}
                />
                <Typography variant="h4" fontWeight={700}>
                    Enter Code
                </Typography>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input disabled={isDisabled} {...props} />}
                    containerStyle={{
                        marginTop: 24,
                        paddingBottom: 64,
                        gap: 12
                    }}
                    inputStyle={{
                        width: 48,
                        height: 48
                    }}
                />
            </Stack>
        </AuthLayout>
    )
}

export default EmailVerification;
