import React, { useState } from 'react';
import { Grid, Typography, Stack, Switch, Box } from '@mui/material';
import MainLayout from '../../components/MainLayout';
import Card from './comps/Card';
import { productPlans } from '../../utils/contants';
import { getLoggedInUserSubscriptionStatus } from '../../utils/methods';

const Plans = () => {
    const [checked, setChecked] = useState(true);
    const currentPackage = localStorage.getItem("currentPackage");
    const isSubscribed = getLoggedInUserSubscriptionStatus();

    return (
        <MainLayout showAuthButton={true} hideUI>
            <Stack gap={8} sx={{ alignItems: 'center' }}>
                <Stack gap={2} sx={{ textAlign: 'center', mt: 3, maxWidth: 1024 }}>
                    <Typography variant="h5" fontWeight={600}>
                        No surprise pricing, accessible to any size bar operation.
                    </Typography>
                    <Typography variant="h6" color={'textSecondary'} fontWeight={300}>
                        Barkeep simplifies the process of assigning and tracking opening and closing
                        procedures, ensuring that every shift runs smoothly and efficiently. With our
                        intuitive interface, Bar Managers can easily oversee bartender responsibilites,
                        reduce errors, and maintain a high standard of service.
                    </Typography>
                </Stack>

                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    background: 'lightgrey',
                    pl: 2,
                    pr: 2,
                    pt: 1,
                    pb: 1,
                    borderRadius: 4,
                    width: 'max-content',
                    cursor: 'pointer'
                }}>
                    <Typography
                        onClick={() => setChecked(true)}
                        sx={checked ? { background: '#fff', width: 'max-content', padding: 1, borderRadius: 2 } : { p: 1 }}>
                        Monthly plans
                    </Typography>
                    <Typography
                        onClick={() => setChecked(false)}
                        sx={!checked ? { background: '#fff', width: 'max-content', padding: 1, borderRadius: 2 } : { p: 1 }}>
                        Annual plans
                    </Typography>
                </Box>

                <Grid container spacing={3} sx={{ pb: 4, justifyContent: 'center' }}>
                    {productPlans?.map((item, i) => (
                        <Grid key={i} item xs={12} md={6} lg={3}>
                            <Card {...item} isPurchased={((item?.plan_type === currentPackage) && isSubscribed)} checked={checked} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </MainLayout>
    )
}

export default Plans