import React, { useEffect, useState } from 'react';
import { Stack, Box, Grid, IconButton, InputAdornment } from '@mui/material';
import { Typography } from '@mui/material';
import AuthLayout from '../../components/AuthLayout';
import mainLogo from '../../assets/main-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Loader from '../../components/Loader';
import Form from 'rc-field-form';
import { LoadingButton } from '@mui/lab';
import { Input } from '../../components/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { generatePasswordResetToken, validatePasswordResetToken } from '../../utils/http';
import { toastConfiguration } from '../../utils/contants';

const ResetPassword = () => {
    const [form] = Form.useForm();
    const { token } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [showResetForm, setShowResetForm] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [confShowPassword, setConfShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickConfShowPassword = () => setConfShowPassword((show) => !show);

    useEffect(() => {
        if (token) {
            setShowResetForm(true);
        }
        setLoading(false);
    }, [token])

    const onSubmit = async (values) => {
        const { username, password } = values;

        setLoading(true);
        if (username && !token && !showResetForm) {
            const { data, error } = await generatePasswordResetToken({
                email: username
            })

            if (data) {
                toast.success("Reset link has been sent.", toastConfiguration);
            } else if (error) {
                toast.error(error || "Error in sending reset link", toastConfiguration);
            }

        } else if (token && password && showResetForm) {
            const { data, error } = await validatePasswordResetToken({
                token,
                password
            })

            if (data) {
                toast.success("Password updated successfully", toastConfiguration);
                navigate("/login");
            } else if (error) {
                toast.error(error || "Error in updating password", toastConfiguration);
            }
        }
        setLoading(false);
    }

    return (
        <AuthLayout backButton={true} backButtonText={'Back to signIn'} backbuttonRedirection={"/login"}>
            <Loader open={loading} />
            <ToastContainer />
            <Stack alignItems={'center'}>
                <Box
                    component={'img'}
                    src={mainLogo}
                    alt='barkeep'
                    sx={{
                        maxWidth: 200,
                        width: '100%',
                        mx: 'auto',
                    }}
                />
                <Typography variant="h4" fontWeight={700}>
                    Reset Password
                </Typography>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    style={{ width: '100%' }}
                >
                    <Grid container spacing={2.5} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            {showResetForm ? (
                                <>
                                    <Grid item xs={12} mt={1}>
                                        <Input
                                            label={'Password'}
                                            name={'password'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Password is required.'
                                                },

                                            ]}
                                            variant="outlined"
                                            fullWidth
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            size='small'
                                                            edge={'end'}
                                                        >
                                                            {showPassword
                                                                ? <VisibilityOffOutlinedIcon fontSize='small' />
                                                                : <VisibilityOutlinedIcon fontSize='small' />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} mt={2}>
                                        <Input
                                            label={'Confirm Password'}
                                            name={'confirm_password'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Confirm is required.'
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The confirm password that you entered do not match!'));
                                                    },
                                                }),

                                            ]}
                                            variant="outlined"
                                            fullWidth
                                            type={confShowPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle confirm password visibility"
                                                            onClick={handleClickConfShowPassword}
                                                            size='small'
                                                            edge={'end'}
                                                        >
                                                            {confShowPassword
                                                                ? <VisibilityOffOutlinedIcon fontSize='small' />
                                                                : <VisibilityOutlinedIcon fontSize='small' />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </>
                            )
                                :
                                <Input
                                    label={'Email'}
                                    name={'username'}
                                    disabled={showResetForm}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email.'
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please provide valid email!'
                                        }
                                    ]}
                                    variant="outlined"
                                    fullWidth
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton type="submit" fullWidth variant="contained">
                                {showResetForm ? "Reset Password" : "Get Reset Link"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            </Stack>
        </AuthLayout>
    )
}

export default ResetPassword;
