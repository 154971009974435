import React, { useState } from 'react';
import Form from 'rc-field-form';
import { Link, useNavigate } from 'react-router-dom';
import { Stack, Box } from '@mui/material';
import { Grid, InputAdornment, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Input } from '../../components/Input';
import { Checkbox } from '../../components/Checkbox';
import AuthLayout from '../../components/AuthLayout';
import mainLogo from '../../assets/main-logo.png';
import { loginHandler } from '../../utils/http';
import { ToastContainer, toast } from 'react-toastify';
import { Messages, toastConfiguration } from '../../utils/contants';
import Loader from '../../components/Loader';

const Login = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const onSubmit = async (values) => {
        const { username, password } = values;

        if (username && password) {
            setLoading(true);
            const { data, error } = await loginHandler({
                email: username,
                platform: 'web',
                password
            })
            if (data) {
                localStorage.setItem("token", data?.token);
                localStorage.setItem("isSubscribed", data?.isSubscribed);
                if(data?.isSubscribed) localStorage.setItem("currentPackage", data?.currentPackage)
                navigate("/dashboard");
            } else if (error) {
                if (error === Messages.emailPendingConfirmation) {
                    navigate("/email-verification", { state: { email: username } });
                    return;
                }
                toast.error(error || "Error in signing up", toastConfiguration);
            }
            setLoading(false);
        }
    }

    return (
        <AuthLayout backButton={true} backButtonText={'Back to plans'}>
            <ToastContainer />
            <Loader open={loading} />
            <Stack alignItems={'center'}>
                <Box
                    component={'img'}
                    src={mainLogo}
                    alt='barkeep'
                    sx={{
                        maxWidth: 200,
                        width: '100%',
                        mx: 'auto',
                    }}
                />
                <Typography variant="h4" fontWeight={700}>
                    Login
                </Typography>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    style={{ width: '100%' }}
                >
                    <Grid container spacing={2.5} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <Input
                                label={'Email'}
                                name={'username'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email.'
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please provide valid email!'
                                    }
                                ]}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label={'Password'}
                                name={'password'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Password is required.'
                                    },

                                ]}
                                variant="outlined"
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                size='small'
                                                edge={'end'}
                                            >
                                                {showPassword
                                                    ? <VisibilityOffOutlinedIcon fontSize='small' />
                                                    : <VisibilityOutlinedIcon fontSize='small' />
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Typography variant='body2'
                                onClick={() => navigate("/reset-password")}
                                sx={{
                                    mt: 1,
                                    textAlign: 'end',
                                    color: 'primary.main',
                                    cursor: 'pointer'
                                }}>
                                Reset Password
                            </Typography>

                            {/* <Checkbox
                                name={'remember_me'}
                                sx={{ mt: 1 }}
                                label="Remember me"
                            /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton type="submit" fullWidth variant="contained">
                                Login
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            </Stack>
        </AuthLayout>
    )
}

export default Login
