export const light = {
  primary: {
    main: "#ff5055",
    light: "#FF6F73",
    dark: "#EC4347",
  },
  info: {
    main: "#00B6FF",
  },
  background: {
    default: '#F8F8F8',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#3C3C3C',
  },
  secondary: {
    main: "#11cb5f",
  },
  divider: '#A87273',
};
