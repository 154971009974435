import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, useMediaQuery } from '@mui/material';
import Logo from '../../assets/logo.png';
import { Assignment, Dashboard, Group, LocationOn } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const color = '#FF5055'
const options = [
    {
        title: 'Dashboard',
        url: '/dashboard'
    },
    {
        title: 'Teams',
        url: '/teams'
    },
    {
        title: 'Locations',
        url: '/locations'
    },
    {
        title: 'Forms',
        url: '/questionnaire'
    },
]

const Sidebar = ({ open, toggleDrawer }) => {
    const isMaxWidth724 = useMediaQuery('(max-width:724px)');
    const navigate = useNavigate();

    const list = () => (
        <Box
            role="presentation"
            onKeyDown={toggleDrawer(false)}
            sx={{
                width: isMaxWidth724 ? '100%' : 240,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: isMaxWidth724 ? 'center' : 'unset'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '16px',
                    backgroundColor: '#FFFFFF',
                }}
            >
                <img src={Logo} alt="Logo" width="100" />
            </Box>
            <List>
                {options?.map((item) => {
                    let icon;
                    switch (item.title) {
                        case 'Dashboard':
                            icon = <Dashboard sx={{ color: color }} />;
                            break;
                        case 'Teams':
                            icon = <Group sx={{ color: color }} />;
                            break;
                        case 'Locations':
                            icon = <LocationOn sx={{ color: color }} />;
                            break;
                        case 'Forms':
                            icon = <Assignment sx={{ color: color }} />;
                            break;
                        default:
                            break;
                    }
                    return (
                        <ListItem
                            onClick={() => {
                                navigate(item.url);
                                toggleDrawer(false)
                            }}
                            key={item.title}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#FF5055',
                                    '& .MuiTypography-root': {
                                        color: '#FFF',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#FFF',
                                    },
                                },
                            }}
                        >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primary={item.title} sx={{ color: color }} />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={toggleDrawer(false)}
            sx={{ '& .MuiDrawer-paper': { width: isMaxWidth724 ? '100%' : 240 } }}
        >
            {list()}
        </Drawer>
    );
};

export default Sidebar;
