
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import './App.css';
import theme from "./theme";
import PrivateRoutes from "./routes/PrivateRoutes";
import Dashboard from './pages/Dashboard';
import Plans from './pages/Plans';
import Login from './pages/Login';
import Signup from './pages/Signup';
import EmailVerification from "./pages/EmailVerification";
import ResetPassword from "./pages/ResetPassword";

import 'react-toastify/dist/ReactToastify.css';
import NotSubscribed from "./pages/NotSubscribed";
import PaymentStatus from "./pages/PaymentStatus";
import Locations from "./pages/Locations";
import Teams from "./pages/Teams";
import Questionnaire from "./pages/Questionnaire";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Plans />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/email-verification" element={<EmailVerification />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/reset-password/:token" element={<ResetPassword />} />
          <Route exact path="/not-subscribed" element={<NotSubscribed />} />
          <Route exact path="/payment-status/:status" element={<PaymentStatus />} />
          <Route element={<PrivateRoutes />}>
            <Route exact path="/teams" element={<Teams />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/locations" element={<Locations />} />
            <Route exact path="/questionnaire" element={<Questionnaire />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
