import axios from 'axios';
import { getLoggedInUserToken } from './methods';
import { toast } from 'react-toastify';
import { toastConfiguration } from './contants';

export const getInstance = (
    Authorization,
    contentType,
    responseType,
) => {
    return axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': contentType,
            ...(Authorization ? { Authorization } : {}),
        },
        ...(responseType ? { responseType } : {}),
    });
};

const handleResponse = ({ data }) => {
    if (data) {
        if (data?.data?.token) localStorage.setItem("token", data.data.token)
        return { code: 200, data: data?.data, message: data.message };
    }
};

const handleErrorResponse = ({ request, response, message }) => {
    if (response) {
        if (response?.data?.error?.message === 'Not authenticated.') {
            localStorage.clear();
            window.location = '/'
        }
        return {
            code: response.status,
            error:
                response.data?.error?.message ||
                response.statusText ||
                response.message,
        };
    } else if (request) {
        return { code: 500, error: 'Unable to send request. Try again later!' };
    } else {
        return { code: 500, error: message };
    }
};

const sendPostRequest = async (
    url,
    data,
    contentType,
    token
) => {
    try {
        const res = await getInstance(
            token || getLoggedInUserToken(),
            contentType,
            null,
        ).post(url, data);
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error);
    }
};

const sendGetRequest = async (url, contentType) => {
    try {
        const res = await getInstance(
            getLoggedInUserToken(),
            contentType,
            null,
        ).get(url);
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error);
    }
};

export const signUpHandler = async (data) => {
    try {
        const response = await sendPostRequest('/users/signup', data);

        // If the server responds with a success, return the data
        if (response.data) {
            return response;
        } else {
            // If there's an error, show a toast message
            if (response.error) {
                // Check if the error message indicates that the email already exists
                if (response.error === 'Email already exists') {
                    toast.error('The email is already in use in our system. Please Sign in, or select Forgot Password to reset access.', toastConfiguration);
                } else {
                    // Show a generic error message
                    toast.error(response.error, toastConfiguration);
                }
            } else {
                // Show a generic error message if no specific error message is provided
                toast.error('Error in signing up', toastConfiguration);
            }
        }
    } catch (error) {
        // Handle any network or unexpected errors
        toast.error('An unexpected error occurred. Please try again later.', toastConfiguration);
    }

    // Return an empty object if there's an error, to maintain the function's return type
    return {};
};



export const loginHandler = async (data) => {
    return await sendPostRequest('/users/login', data);
};

export const emailVerificationHandler = async (data) => {
    return await sendPostRequest('/users/validateConfirmationToken', data);
};

export const generatePasswordResetToken = async (data) => {
    return await sendPostRequest('/users/generatePasswordResetToken', data);
};

export const validatePasswordResetToken = async (data) => {
    return await sendPostRequest('/users/updateInformationOnToken', data);
};

export const inviteTeamMember = async (data) => {
    return await sendPostRequest('/users/inviteTeamMember', data);
};

export const createClientSession = async (data) => {
    return await sendPostRequest('/stripe/createCheckoutSession', data);
};

export const validateUserPayment = async (data) => {
    return await sendPostRequest('/stripe/validateUserPayment', data);
};

export const getAllLocations = async () => {
    return await sendGetRequest('/location/getAllLocations');
};

export const getLocationById = async (id) => {
    return await sendGetRequest(`/location/getLocationById/${id}`);
};

export const updateLocation = async (id, data) => {
    return await sendPostRequest(`/location/updateLocation/${id}`, data);
};

export const addLocation = async (data) => {
    return await sendPostRequest(`/location/addLocation`, data);
};

export const deleteLocation = async (id) => {
    return await sendPostRequest(`/location/deleteLocation/${id}`, {});
};

export const getAllTeamMembers = async () => {
    return await sendGetRequest('/users/getTeamMembers');
};

export const deleteTeamMember = async (id) => {
    return await sendPostRequest(`/users/removeTeamMember/${id}`, {});
};

export const getTeamMemberById = async (id) => {
    return await sendGetRequest(`/users/getTeamMemberById/${id}`);
};

export const udpateTeamMember = async (id, data) => {
    return await sendPostRequest(`/users/updateTeamMember/${id}`, data);
};

export const getAllQuestionnaires = async () => {
    return await sendGetRequest(`/questionnaire/getAllQuestionnaires`);
};

export const deleteQuestionnaire = async (id) => {
    return await sendPostRequest(`/questionnaire/deleteQuestionnaire/${id}`, {});
};

export const getQuestionnaireById = async (id) => {
    return await sendGetRequest(`/questionnaire/getQuestionnaireById/${id}`);
};

export const addNewQuestionnaire = async (data) => {
    return await sendPostRequest(`/questionnaire/addQuestionnaire`, data);
};

export const updateExistingQuestionnaire = async (id, data) => {
    return await sendPostRequest(`/questionnaire/updateQuestionnaire/${id}`, data);
};

export const getAllResponses = async (id, data) => {
    return await sendGetRequest('/responses/getAllResponses');
};

export const getResponseById = async (id) => {
    return await sendGetRequest(`/responses/getResponseById/${id}`);
};

